<template>
  <div class="directions-most-popular my-90" :class="{ 'is-main-page': isMainPage }">
    <h2>
      <vz-icon v-if="!hideTitleIcon" size=20 name="star-outline" />
      {{ title }}
    </h2>
    <div class="directions-most-popular-wrapper flex flex-wrap mt-25">
      <vz-button
        big
        outline
        v-for="(direction, index) in directions"
        :key="index"
        icon-size=16
        class="mr-20 mb-20"
        target="_blank"
        variant="primary"
        icon-name="star-outline"
        :to="getLink(direction.url)"
      >
        {{ direction.title }}
      </vz-button>
    </div>
    <div v-if="isMainPage" class="flex flex-end directions-most-popular-link">
      <vz-button
        link
        variant="primary"
        :to="{ name: 'terminals' }"
        class="text-decoration-underline fs-18 p-0"
      >
        Все направления по России
      </vz-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getDirectionsMostPopular } from '~/api/directions';
import { BASE_URL_HTTPS } from '~/composables/useVzFetch';

const props = defineProps({
  isMainPage: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Самые популярные направления'
  },
  hideTitleIcon: {
    type: Boolean,
    default: false
  }
})

const directions = await getDirectionsMostPopular(props.isMainPage)

function getLink(url: string) {
  return `${BASE_URL_HTTPS}${url}`
}
</script>

<style lang="scss" scoped>
.directions-most-popular {
  @media screen and (max-width: $pub-sm) {
    margin-top: 70px;
    margin-bottom: 70px; 
  }

  h2 {
    text-align: left;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
  }

  &-link {
    margin-top: -20px;

    @media screen and (max-width: $pub-xs) {
      margin-top: 0;
    }
  }

  &-wrapper {
    :deep(.vz-button) {
      @media screen and (max-width: $pub-xs) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        justify-content: center;
      }
    }
  }

  &.is-main-page {
    h2 {
      font-weight: 500;
      font-size: $fs-28;
      line-height: $fs-36;
    }
  }
}
</style>
